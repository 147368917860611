export default {
  'CTA heat pump': [
    {
      index: 0,
      profileName: 'CTA Aeroheat Inverta All-In-One AH CI 8is L/W',
      values: {
        minPower: 3000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 1,
      profileName: 'CTA Aeroheat Inverta All-In-One AH CI 12is L/W',
      values: {
        minPower: 4900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 2,
      profileName: 'CTA Aeroheat Inverta Economy AH CI 8i/a L/W',
      values: {
        minPower: 3000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 3,
      profileName: 'CTA Aeroheat Inverta Economy AH CI 12i/a L/W',
      values: {
        minPower: 4900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 4,
      profileName: 'CTA Aeroheat Inverta Economy AH CI 12i/a L/W',
      values: {
        minPower: 5200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 5,
      profileName: 'CTA Aeroheat Invetra CNI 9a L/W',
      values: {
        minPower: 2900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 6,
      profileName: 'CTA Aeroheat Invetra CN 5a L/W',
      values: {
        minPower: 2500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 7,
      profileName: 'CTA Aeroheat Invetra CN 7a L/W',
      values: {
        minPower: 3400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 8,
      profileName: 'CTA Aeroheat Invetra CN 9a L/W',
      values: {
        minPower: 4500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 9,
      profileName: 'CTA Aeroheat CS 1-14iL, 1-14i/a L/W',
      values: {
        minPower: 5800,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 10,
      profileName: 'CTA Aeroheat CS 1-18iL, 1-18i/a L/W',
      values: {
        minPower: 4000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 11,
      profileName: 'CTA Aeroheat CS 1-25iL, 1-25i/a L/W',
      values: {
        minPower: 6100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 12,
      profileName: 'CTA Aeroheat CS 30iL, 30i/a L/W',
      values: {
        minPower: 6700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 50,
        boilerTempNormal: 50,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 13,
      profileName: 'CTA Aeroheat CM 18a',
      values: {
        minPower: 5600,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 14,
      profileName: 'CTA Aeroheat CS 1-07a L/W',
      values: {
        minPower: 3000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 15,
      profileName: 'CTA Aeroheat CS 1-08a L/W',
      values: {
        minPower: 3500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 16,
      profileName: 'CTA Aeroheat CS 1-10iL, 1-10i/a L/W',
      values: {
        minPower: 4100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 17,
      profileName: 'CTA Aeroheat CS 1-12iL, 1-12i/a L/W',
      values: {
        minPower: 5000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 18,
      profileName: 'CTA Aeroheat CB 1-10iL, 1-10i/a L/W',
      values: {
        minPower: 4100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 19,
      profileName: 'CTA Aeroheat CB 1-18iL, 1-18i/a L/W',
      values: {
        minPower: 4000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 20,
      profileName: 'CTA Aeroheat CS 1-31iL, 1-31i/a L/W',
      values: {
        minPower: 7100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 21,
      profileName: 'CTA Aeroheat CS 6is-BWW-D L/W',
      values: {
        minPower: 2400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 22,
      profileName: 'CTA Aeroheat CS 8is-BWW-D L/W',
      values: {
        minPower: 3200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 23,
      profileName: 'CTA Aeroheat CS 10is-BWW L/W',
      values: {
        minPower: 4200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 24,
      profileName: 'CTA Aeroheat CS 12is-BWW L/W',
      values: {
        minPower: 4800,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 52,
        boilerTempNormal: 51,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 25,
      profileName: 'CTA Aeroheat Livera CL 5a',
      values: {
        minPower: 2200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 26,
      profileName: 'CTA Aeroheat Livera CL 8a',
      values: {
        minPower: 3000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 60,
        boilerTempNormal: 55,
        boilerTempReduced: 50,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    }
  ],
  'CTA heat pump RTU': [
    {
      index: 0,
      profileName: 'CTA OH All In One 1-5es S/W',
      values: {
        minPower: 1700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 1,
      profileName: 'CTA OH All In One 1-6es S/W',
      values: {
        minPower: 2000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 2,
      profileName: 'CTA OH All In One 1-8es S/W',
      values: {
        minPower: 2700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 3,
      profileName: 'CTA OH All In One 1-11es S/W',
      values: {
        minPower: 3500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 4,
      profileName: 'CTA OH All In One 1-14es S/W',
      values: {
        minPower: 4500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 5,
      profileName: 'CTA OH All In One 1-18es S/W',
      values: {
        minPower: 5900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 6,
      profileName: 'CTA OH Eco 1-22e S/W',
      values: {
        minPower: 7100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 7,
      profileName: 'CTA OH Eco 1-25e S/W',
      values: {
        minPower: 8100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 8,
      profileName: 'CTA OH Eco 1-29e S/W',
      values: {
        minPower: 9400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 9,
      profileName: 'CTA OH Eco 1-33e S/W',
      values: {
        minPower: 10600,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 10,
      profileName: 'CTA OH DUO 1-44e S/W',
      values: {
        minPower: 7000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 11,
      profileName: 'CTA OH DUO 1-50e S/W',
      values: {
        minPower: 8000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 12,
      profileName: 'CTA OH DUO 1-58e S/W',
      values: {
        minPower: 9300,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 13,
      profileName: 'CTA OH DUO 1-65e S/W',
      values: {
        minPower: 10300,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 14,
      profileName: 'CTA OH DUO 1-72e S/W',
      values: {
        minPower: 11400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 15,
      profileName: 'CTA OH DUO 1-85e S/W',
      values: {
        minPower: 13400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 16,
      profileName: 'CTA OH DUO HT 22e S/W',
      values: {
        minPower: 3500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 17,
      profileName: 'CTA OH DUO HT 28e S/W',
      values: {
        minPower: 4500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 18,
      profileName: 'CTA OH DUO HT 34e S/W',
      values: {
        minPower: 5900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 19,
      profileName: 'CTA OH All In One 1-5es W/W',
      values: {
        minPower: 1700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 20,
      profileName: 'CTA OH All In One 1-6es W/W',
      values: {
        minPower: 2000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 21,
      profileName: 'CTA OH All In One 1-8es W/W',
      values: {
        minPower: 2700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 22,
      profileName: 'CTA OH All In One 1-11es W/W',
      values: {
        minPower: 3500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 23,
      profileName: 'CTA OH All In One 1-14es W/W',
      values: {
        minPower: 4500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 24,
      profileName: 'CTA OH All In One 1-18es W/W',
      values: {
        minPower: 5900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 25,
      profileName: 'CTA OH Eco 1-22e W/W',
      values: {
        minPower: 7100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 26,
      profileName: 'CTA OH Eco 1-25e W/W',
      values: {
        minPower: 8100,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 27,
      profileName: 'CTA OH Eco 1-29e W/W',
      values: {
        minPower: 9400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 28,
      profileName: 'CTA OH Eco 1-33e W/W',
      values: {
        minPower: 10600,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 29,
      profileName: 'CTA OH DUO 1-44e W/W',
      values: {
        minPower: 7000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 30,
      profileName: 'CTA OH DUO 1-50e W/W',
      values: {
        minPower: 8000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 31,
      profileName: 'CTA OH DUO 1-58e W/W',
      values: {
        minPower: 9300,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 32,
      profileName: 'CTA OH DUO 1-65e W/W',
      values: {
        minPower: 10300,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 33,
      profileName: 'CTA OH DUO 1-72e W/W',
      values: {
        minPower: 11400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 34,
      profileName: 'CTA OH DUO 1-85e W/W',
      values: {
        minPower: 13400,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 35,
      profileName: 'CTA OH DUO HT 22e W/W',
      values: {
        minPower: 3500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 36,
      profileName: 'CTA OH DUO HT 28e W/W',
      values: {
        minPower: 4500,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 37,
      profileName: 'CTA OH DUO HT 34e W/W',
      values: {
        minPower: 5900,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        roomTempReduced: 20,
        roomTempNormal: 22,
        roomTempOverproduction: 25,
        roomTempReducedHk2: 20,
        roomTempNormalHk2: 22,
        roomTempOverproductionHk2: 25,
        heatpumpControlMethod: 0
      }
    }
  ],
  'CTA carel': [
    {
      index: 0,
      profileName: 'CTA OHI 4esr TWW S/W Inverter',
      values: {
        minPower: 1000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 1,
      profileName: 'CTA OHI 9e S/W Inverter',
      values: {
        minPower: 2700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 2,
      profileName: 'CTA OHI 9ec S/W Inverter',
      values: {
        minPower: 2700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 3,
      profileName: 'CTA OHI 17e S/W Inverter',
      values: {
        minPower: 5200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 4,
      profileName: 'CTA OHI 4esr TWW W/W Inverter',
      values: {
        minPower: 1000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 5,
      profileName: 'CTA OHI 9e W/W Inverter',
      values: {
        minPower: 2700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 6,
      profileName: 'CTA OHI 9ec W/W Inverter',
      values: {
        minPower: 2700,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 7,
      profileName: 'CTA OHI 17e W/W Inverter',
      values: {
        minPower: 5200,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    },
    {
      index: 8,
      profileName: 'CTA Aeroheat SCI 25',
      values: {
        minPower: 6000,
        switchOnDelay: 10,
        switchOffDelay: 5,
        minimalOnDuration: 20,
        boilerTempOverproductionEnable: false,
        heatingBufferOverproduction: 10,
        heatingBufferOptimizationEnable: false,
        boilerTempOverproduction: 55,
        boilerTempNormal: 53,
        boilerTempReduced: 51,
        ComfortOffsetMinimum: -2,
        ComfortOffsetMaximum: 2,
        heatpumpControlMethod: 0
      }
    }
  ]
};
