import { pick } from 'lodash';
import formatFloatSeparator from '../../../utils/formatFloatSeparator';
import fieldsForDevices from './fieldsForDevices';
import { getUpdatedFieldList } from './getUpdatedFieldList';

const transformFieldsForAPI = (fields) => {
  const newFields = JSON.parse(JSON.stringify(fields));

  const allowedDataFields = getUpdatedFieldList(newFields.device_group, newFields.data);
  newFields.data = pick(newFields.data, allowedDataFields);

  if (newFields.data?.ip) {
    if (fieldsForDevices[newFields.device_group]?.includes('ip')) {
      newFields.ip = newFields.data.ip;
    }
    delete newFields.data.ip;
  }

  if (newFields.createNewTag) {
    if (newFields.createNewTag === newFields.tag) {
      newFields.createNewTag = true;
    } else {
      delete newFields.createNewTag;
    }
  }

  if (newFields.data?.User) {
    newFields.data.User = newFields.data.User.trim();
  }

  return newFields?.data
    ? { ...newFields, data: formatFloatSeparator(newFields.data) }
    : newFields;
};

export default transformFieldsForAPI;
